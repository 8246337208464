// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

// globals
// reference https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
window.bootstrap = global.bootstrap = require('bootstrap');

$(document).on('blur', 'input[name=query], input[type=text], input[type=search], textarea', function() {
    var el = $(this);
    var text = el.val();
    if (text) {
        var normalized = text.normalize('NFC');
        if (text !== normalized) {
            console.log("Normalizing input");
            el.val(normalized);
        }
        else {
            console.log("Input not needing normalization");
        }
    }
})


require("trix")
require("@rails/actiontext")